import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import Image from "../../../components/image"
import SEO from "../../../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Drill Sergeant Terms" />
    <h1>TERMS</h1>
    <p>
    You can use our Alexa Skills for free. For some skills (Drill Sergeant, Dr Sugar) you can get a monthly subscription to enable additional features. For some skills there is a trial period. Please refer to the alexa skill for details on the deals. <br/>
    This is the first version of our terms which will get updated very soon.
    </p>
    <h2 id="de">NUTZUNGSBEDINGUNGEN</h2>
      <p>
        Die Grundfunktionen der Alexa Skills sind gratis. Bei manchen (z.B. Drill Sergeant) bieten wir eine Mitgliedschaft an. Damit schalten Sie mehr Funktionen frei. Beispielsweise kann der Skill "Drill Sergeant" ohne Mitgliedschaft nur eine Aktivität tracken. Mit einer aktiven Mitgliedschaft können dann z.B. Situps, Liegestütze, Klimmzüge, Joggen, Radfahren, Dehnen und vieles mehr im Überblick behalten.<br/>
        Genauere Infos zu Gebühren, Laufzeiten und sonstigen Bestimmungen finden Sie direkt im Alexa Skill
      </p>
      <p>Das ist die erste Version von unseren Nutzungsbedingungen die bald ein Update bekommt.</p>
  </Layout>
)

export default IndexPage